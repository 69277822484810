import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import Main from "../components/Main";

import { PageHeader, H3, SectionHeader } from "../components/Typo";
import QuoteBox from "../components/QuoteBox";
import Section from "../components/Section";
import MediaBottom from "../components/MediaBottom";
import VideoCarousel from "../components/VideoCarousel";
import { VideoModal } from "../components/VideoModal";

import photo75 from "../images/75.jpeg";

import YouTube from "simple-youtube-api";

// eslint-disable-next-line
import { squareImage } from "../image";

const Column = styled.div`
  flex: 1 1 50%;

  &:first-child {
    margin-right: 64px;

    ${media.lessThan("medium")`
      margin-right: 0px;
      margin-bottom: 24px;
    `}
  }
`;

const Prices = styled.div`
  position: relative;
  flex: 1 1 50%;
  padding: 32px 48px;
  border-radius: 2px;

  ${media.lessThan("medium")`
  top: 0px;
  padding: 24px;
    `}

  background-color: var(--greybg);
`;

const Description = styled.div`
  max-width: 960px;
  margin: auto;
  padding: 0px 16px;

  ${media.lessThan("medium")`
    padding: 0px 16px;
  `}

  & > img {
    ${media.lessThan("medium")`
      display: none;
  `}
  }
`;

const PlaylistSection = styled(Section)`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 76px;

  ${media.lessThan("medium")`
    margin-top: 56px;
  `}
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "1.jpg" }) {
        ...squareImage
      }
    }
  `);

  const [videos, setVideos] = React.useState(null);

  const [videoId, setVideoId] = React.useState(null);

  React.useEffect(() => {
    const yt = new YouTube("AIzaSyAb8HpPh9mGryfQBGA6ymVc7AnClLXojIs");

    yt.getPlaylist(
      "https://www.youtube.com/playlist?list=PLfRJRCjdGa87Ik07qTPTIubICiTYAItNm"
    )
      .then((x) => x.getVideos(10))
      .then(setVideos);
  }, []);

  return (
    <Main title="Zangles in Amersfoort" path="/zangles">
      <VideoModal videoId={videoId} onClose={() => setVideoId(null)} />
      <QuoteBox
        style={{
          maxWidth: 960,
          margin: "auto",
          boxSizing: "border-box",
          marginBottom: 64,
          // marginTop: -64
        }}
        padding={"0px 76px"}
        quote={`"VUL JE LONGEN MET LUCHTIGHEID EN ZING ZE UIT JE LIJF!"`}
        source="Emma Lou"
      />
      <PageHeader title="Zangles in Amersfoort" icon="microphone" />
      <Description>
        <img
          alt=""
          src={photo75}
          style={{
            float: "right",
            marginLeft: 76,
            marginBottom: 32,
            width: 300,
          }}
        />
        <p style={{ marginTop: 0 }}>
          Heb je zin om te zingen, je techniek te verbeteren of zelfverzekerder
          je stem te laten horen? Wat je doel dan ook is, ik help je hierbij!
        </p>
        <p>
          In de lessen besteed ik aandacht aan wat jij nodig hebt om jouw
          muzikaliteit optimaal te ontwikkelen. Bijvoorbeeld performance,
          tekstbeleving, gezond stemgebruik, techniek, timing, intonatie, sound
          of het ontdekken welke muziekstijl goed bij je past. Vrij zingen
          zonder schaamte of blokkade's speelt een belangrijke rol in mijn
          lessen.
        </p>
        <p>
          Adres:{" "}
          <address style={{ display: "inline" }}>
            Huis Miereveldstraat, Miereveldstraat 72, Amersfoort
          </address>
        </p>

        <p>
          Aanmelden via <a href="mailto:info@emmalou.nl">info@emmalou.nl</a>
        </p>
      </Description>
      <PlaylistSection>
        <SectionHeader title="Videos" center noMargin />

        {videos && <VideoCarousel items={videos} onClick={setVideoId} />}
      </PlaylistSection>

      <Section flex style={{ marginTop: 76 }}>
        <Prices>
          <H3>Tarieven</H3>
          <p>Proefles: €15,-</p>
          <p>Zangles: 40 minuten a €32,-</p>
        </Prices>
        <Column>
          {/* <Img
            fluid={data.photo.childImageSharp.fluid}
            alt={""}
            fadeIn={true}
            durationFadeIn={100}
            style={{ height: 250 }}
          /> */}
        </Column>
      </Section>

      <MediaBottom videoId="PFC4XlRJ5Us" />
    </Main>
  );
};
